import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useUsersList() {
  const usersListTable = ref([])

  const tableColumns = [
    { text: 'First Name', value: 'first_name', sortable: false },
    { text: 'Last Name', value: 'last_name', sortable: false },
    { text: 'Mobile Number', value: 'mobile_number', sortable: false },
    { text: 'Balance', value: 'points', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const phoneFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const usersTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchUsers = () => {
    options.value.q = searchQuery.value
    store
      .dispatch('app-users/fetchUsers', {
        options: options.value,
        status: statusFilter.value,
        phone: phoneFilter.value,
        plan: planFilter.value,
      })
      .then(response => {
        const { filteredData, total } = response.data.data
        usersListTable.value = filteredData
        console.log('usersListTable.value', response.data.data)
        totalUserListTable.value = total
        usersTotalLocal.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }
  const chargeUsers = data => {
    data.users = selectedRows.value
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-users/chargeUsers', data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
          console.error(error)
        })
    })
  }

  watch([searchQuery, phoneFilter, planFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchUsers()
  })

  return {
    usersListTable,
    tableColumns,
    searchQuery,
    phoneFilter,
    planFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    usersTotalLocal,
    selectedRows,
    fetchUsers,
    chargeUsers,
  }
}

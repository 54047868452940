import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    chargeUsers(ctx,usersData) {
      console.log('usersData',usersData);
      const bodyFormData = new FormData()
      bodyFormData.append('points', usersData.points)
      usersData?.users?.forEach((element,idx) => {
        bodyFormData.append(`users[${idx}]`, element.id)
      });

      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/charge-individual-user',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },

  },
}

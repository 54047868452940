<template>
  <div id="chef-list">
    <!-- list filters -->
    <v-card>
      <v-card-title> Charge for Users </v-card-title>
      <!-- <v-row class="px-2 ma-0">
        <v-col cols="12">
          <div class="d-flex align-center flex-wrap navigation">
            <v-btn color="primary" class="mb-4 me-3" :to="{ name: 'apps-charge-for-users-list' }">
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Charge</span>
            </v-btn>
            <v-btn color="secondary" outlined class="mb-4" :to="{ name: 'apps-history-for-users-list' }">
              <span>History</span>
            </v-btn>
          </div>
        </v-col>
      </v-row> -->

      <v-divider class="mt-6"></v-divider>

      <!-- actions -->
      <v-card-text cols="12" class="d-flex flex-wrap pb-0 flex-column">
        <v-row cols="12">
          <v-col cols="12">
            <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
              <v-row>
                <v-col cols="12" sm="5">
                  <v-text-field
                    v-model="chargeDataLocal.points"
                    outlined
                    dense
                    maxLength="5"
                    @keypress="isNumber($event)"
                    label="Number of points / person"
                    :rules="[validators.integerValidator]"
                  ></v-text-field>
                </v-col>


                <v-col cols="12" sm="3" class="d-flex justify-center">
                  <v-btn color="primary" type="submit" @click.prevent="onSubmit">
                    <v-icon>{{ icons.mdiPlus }}</v-icon> <span>Charge</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <!-- search -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="searchQuery"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="chef-search me-3 mb-4"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-spacer class="mt-7"></v-spacer>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="usersListTable"
        :options.sync="options"
        :server-items-length="totalUserListTable"
        :loading="loading"
        show-select
        @refetch-data="fetchUsers"
      >
        <template #[`item.first_name`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.first_name ? item.first_name : '___'
          }}</span>
        </template>
        <template #[`item.last_name`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.last_name ? item.last_name : '___'
          }}</span>
        </template>
        <template #[`item.mobile_number`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.mobile_number ? item.mobile_number : '___'
          }}</span>
        </template>
        <template #[`item.points`]="{ item }">
          <span class="text-capitalize font-weight-semibold text--primary">{{
            item.points ? item.points : '___'
          }}</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="">
                <router-link :to="{ name: 'apps-user-view', params: { id: item.id } }">
                  <v-icon>{{ icons.mdiEyeOutline }}</v-icon>
                </router-link>
              </v-btn>
            </template>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import _ from 'lodash'
import { mdiPlus, mdiEyeOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { required, integerValidator } from '@core/utils/validation'

// sidebar
import userStoreModule from '../userStoreModule'
import useUsersList from './useUsersList'

export default {

  setup(props, { emit }) {
    const USERS_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(USERS_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USERS_APP_STORE_MODULE_NAME)) store.unregisterModule(USERS_APP_STORE_MODULE_NAME)
    })
    const {
      usersListTable,
      tableColumns,
      searchQuery,
      totalUserListTable,
      loading,
      options,
      selectedRows,
      chargeUsers,
      fetchUsers,
    } = useUsersList()

    const valid = ref(false)
    const isLoading = ref(false)
    const chargeDataLocal = ref({
      points: null,
      users: [],
    })
    const form = ref(null)
    const planOptions = [
      { title: 'Paid', id: '1' },
      { title: 'Free', id: '2' },
    ]
    const validate = () => {
      form.value.validate()
    }
    const isNumber = evt => {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
    const onSubmit = async () => {
      if (valid.value) {
        isLoading.value = true
        chargeUsers(chargeDataLocal.value)
          .then(() => {
            chargeDataLocal.value = {
              points: '',
              users: [],
            }
            selectedRows.value = []
            form.value.reset()
            fetchUsers()
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Users has been charged successfully',
              showConfirmButton: true,
            })
          })
          .catch(error => {
            let textValue = null
            if (_.isObject(error.data.message)) {
              textValue = `${Object.values(error.data.message).join('\n')}`
            } else {
              textValue = error.data.message
            }
            Swal.fire({
              icon: 'error',
              title: `Error`,
              text: textValue,
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        validate()
      }
    }

    return {
      isLoading,
      isNumber,
      chargeDataLocal,
      valid,
      form,
      onSubmit,
      usersListTable,
      tableColumns,
      searchQuery,
      totalUserListTable,
      planOptions,
      loading,
      options,
      selectedRows,
      fetchUsers,

      validators: {
        required,
        integerValidator,
      },

      // icons
      icons: {
        mdiPlus,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';

.navigation {
  justify-content: flex-end;
}
.swal2-html-container {
  white-space: pre-line;
}
</style>
